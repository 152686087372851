/**
 * Minimalistic Scrollspy
 * source: https://jsfiddle.net/mekwall/up4nu/
 */
jQuery(document).ready(function($) {

  $(window).scrollTop(0);

  // Cache selectors
  var lastId,
      topMenu = $("#menu-hoofdmenu"),
      topMenuHeight = 158; //topMenu.outerHeight()+15,
      // All list items
      menuItems = topMenu.find("a"),
      // All next-section links
      nextItems = $('.next-section'),
      // Anchors corresponding to menu items
      scrollItems = menuItems.map(function(){
        var item = $($(this).attr("href"));
        if (item.length) { return item; }
      });

  // Bind click handler to menu items
  // so we can get a fancy scroll animation
  menuItems.click(function(e){
    if ( $('body').hasClass('home') ) {
      var href = $(this).attr("href"),
          offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
      $('html, body').stop().animate({
          scrollTop: offsetTop
      }, 300);
    } else {
      window.location.href = "//localhost:3000";
    }
    e.preventDefault();
  });

  // so we can get a fancy scroll animation
  nextItems.click(function(e){
    // console.log('topMenuHeight = '+topMenuHeight);
    var href = $(this).attr("href"),
        offsetTop = href === "#" ? 0 : $(href).offset().top-topMenuHeight+1;
    $('html, body').stop().animate({
        scrollTop: offsetTop
    }, 300);
    e.preventDefault();
  });

  // Bind to scroll
  $(window).scroll(function(){
     // Get container scroll position
     var fromTop = $(this).scrollTop()+topMenuHeight;

     // Get id of current scroll item
     var cur = scrollItems.map(function(){
       if ($(this).offset().top < fromTop) {
         return this;
       }
     });
     // Get the id of the current element
     cur = cur[cur.length-1];
     var id = cur && cur.length ? cur[0].id : "";

     // Hack to set contact section active. This section is at the bottom of the page and is not reached when scrolling down.
     if($(window).scrollTop() + $(window).height() === $(document).height()) {
       id = 'contact';
     }

     if (lastId !== id) {
         lastId = id;
         // Set/remove active class
         //console.log('active');
         menuItems
           .parent().removeClass("current_page_item")
           .end().filter("[href='#"+id+"']").parent().addClass("current_page_item");
     }
  });

});
